import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>SOME OF OUR FAVOURITES</h2>
          <p>
            Our tops picks for you to try out. We have a wide range of flavours to choose from.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4" style={{
                maxHeight: "400px",
                overflowY: "hidden",
                padding: "1rem",
              }}>
                {" "}
                <img src={d.image} className="flavour-image" />
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
