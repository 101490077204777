import { useEffect } from 'react';

const MapWidget = () => {
    useEffect(() => {
        // Dynamically load the review widget script
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.async = true;
        document.body.appendChild(script);

        // Cleanup: remove the script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            {/* The map widget */}
            <div class="elfsight-app-8c8c38df-dc0b-4ae0-8840-aa443f0982c5" data-elfsight-app-lazy></div>
        </div>
    );
};

export default MapWidget;