import { useEffect } from 'react';

const ReviewWidget = () => {
    useEffect(() => {
        // Dynamically load the review widget script
        const script = document.createElement('script');
        script.src = "https://grwapi.net/widget.min.js";
        script.async = true;
        document.body.appendChild(script);

        // Cleanup: remove the script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            {/* The review widget */}
            <div
                className="review-widget_net"
                data-uuid="9d95f08a-5773-4799-a29a-dcc585dc776a"
                data-template="2"
                data-lang="en"
                data-theme="light"
            ></div>
            <div class="review-widget_net" data-uuid="9d95f08a-5773-4799-a29a-dcc585dc776a" data-template="3" data-lang="en" data-theme="light"></div>
        </div>
    );
};

export default ReviewWidget;