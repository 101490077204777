/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 triple_scoop_icecream.glb --transform 
Files: triple_scoop_icecream.glb [539.42KB] > /Users/charliesimpson/Documents/PERSONAL/YOURMUMSWEBSITE/Capannina/capannina-gelato/public/3d-assets/triple_scoop_icecream-transformed.glb [37.06KB] (93%)
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Icecream(props) {
  const { nodes, materials } = useGLTF('/3d-assets/triple_scoop_icecream-transformed.glb')
  return (
    <group {...props} dispose={null} scale={[3, 3, 3]}>
      <mesh geometry={nodes.Node.geometry} material={materials.PaletteMaterial001} />
    </group>
  )
}

useGLTF.preload('/3d-assets/triple_scoop_icecream-transformed.glb')
