import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import MapWidget from "./googleMap";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>You'll find us here</h2>
                <p>
                  Located in the heart of Port Douglas, we are easy to find. Come and visit us today.
                </p>
              </div>
              <MapWidget />
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                32 Macrossan St, Port Douglas QLD 4877
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Capannina{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
