import React from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import { Icecream } from "./iceCream";
import ReviewWidget from "./googleRating";

const Scene = () => {
  return (
    <>
      {/* Icecream Model */}
      <Icecream />

      {/* Ambient Light */}
      <ambientLight intensity={0.3} />

      {/* Environment for realistic lighting */}
      <Environment preset="sunset" background={false} />
    </>
  );
};

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="hero-title">
                <h2 className="text-center">#1 RATED GELATERIA</h2>
                <h1>PORT DOUGLAS</h1>
              </div>
              <div className="col-md-8 col-md-offset-2 intro-text">
                <div className="ice-cream-wrapper">
                  <Canvas camera={{ fov: 50, position: [0, 0, 6] }}>
                    <OrbitControls autoRotate={true} enableZoom={false} enableRotate={false} />
                    <Scene />
                  </Canvas>
                </div>
                <div className="review-widget" style={{ marginTop: "40px" }}><ReviewWidget /></div>
                <a
                  target="blank"
                  href="https://www.google.com/maps/dir//32+Macrossan+St,+Port+Douglas+QLD+4877/@-16.4827886,145.3820061,33825m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x6982a875dd6583db:0x212ba770c0d17c05!2m2!1d145.4643044!2d-16.4827325?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D"
                  className="btn btn-custom btn-lg page-scroll "
                >
                  FIND US →
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
